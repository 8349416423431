import React from "react";

//IMAGES
import teamLogo from "../../assets/teamLogo.jpg";
import basLogo from "../../assets/bas.png";
import vubUni from "../../assets/vubInu.png";
import vienaLogo from "../../assets/vienaUni.png";
import cambraLogo from "./../../assets/LogoConsell.png";
//ESTILS
import "./Team.css";
import Button from "../Button/Button";
const Team = () => {
  return (
    <div className="team__container">
      <p className="team__title">The team</p>
      <div className="team__middle-wrapper">
        <p>
          Universitat Autònoma de Barcelona leads a team including five partners
          and five associated partners. Besides UAB, the other partners are the
          Bulgaria Academy of Sciences, the Council of the Catalan Chambers of
          Commerce, the Free University of Brussels and the University of
          Vienna. The associated partners are Barcelona Activa, Education-
          Sofia, Molengeek (Brussels), Open Society Foundation- Sofia and
          Statistik Austria
        </p>
        <Button text={"Read more"} color={"#1d3561"} route={"/team"} />
      </div>
      <div className="team__image-wrapper">
        <img src={teamLogo} alt="test" className="team__logo" />
        <img src={basLogo} alt="test" className="team__logo" />
        <img src={vubUni} alt="test" className="team__logo" />
        <img src={vienaLogo} alt="test" className="team__logo" />
        <img src={cambraLogo} alt="test" className="team__logo" />
      </div>
    </div>
  );
};

export default Team;
